import { ReactNode } from "react";
import { Typography } from "@mui/material";
import "./ChartContainer.scss";

type ChartContainerProps = {
  title: string;
  children: ReactNode;
};

const ChartContainer = ({ title, children }: ChartContainerProps) => {
  return (
    <div className="chart-container">
      <div className="container-title">
        <Typography variant="h4">{title}</Typography>
      </div>
      <div className="charts">{children}</div>
    </div>
  );
};
export default ChartContainer;
