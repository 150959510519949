import { createTheme, ThemeProvider } from "@mui/material/styles";
import type { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import type { ReactNode } from "react";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#304FFE",
      dark: "#0026CA",
      light: "#7A7CFF",
    } satisfies SimplePaletteColorOptions,
    info: {
      main: "#3988FF",
      dark: "#0052CC",
      light: "#D7E7FF",
    } satisfies SimplePaletteColorOptions,
    warning: {
      main: "#F5A623",
      dark: "#E4931A",
      light: "#FFF9E5",
    } satisfies SimplePaletteColorOptions,
    error: {
      main: "#D0021B",
      dark: "#FB1531",
      light: "#FAE6E8",
    } satisfies SimplePaletteColorOptions,
    success: {
      main: "#00875A",
      dark: "#02C66A",
      light: "#E6F9F0",
    } satisfies SimplePaletteColorOptions,
    text: { secondary: "#606060" },
  }, //304ffe
  components: {
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiIcon: {
      defaultProps: {
        classes: { root: "material-symbols-outlined material-icons-outlined" },
      },
    },
    MuiChip: {
      defaultProps: {
        classes: { icon: "material-symbols-outlined" },
      },
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
});
export const withMuiTheme = (Story: any) => (
  <ThemeProvider theme={theme}>
    <Story />
  </ThemeProvider>
);

export const MuiThemeV5 = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
