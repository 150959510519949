import { IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./Footer.scss";
import { l } from "../../Lang";

// TODO: Change the social media links
// const SOCIAL = [
//   {
//     icon: InstagramIcon,
//     link: "https://www.instagram.com/",
//     tooltip: "Instagram",
//   },
//   {
//     icon: XIcon,
//     link: "https://www.x.com/",
//     tooltip: "X",
//   },
//   {
//     icon: FacebookIcon,
//     link: "https://www.facebook.com/",
//     tooltip: "Facebook",
//   },
//   {
//     icon: LinkedInIcon,
//     link: "https://www.linkedin.com/",
//     tooltip: "LinkedIn",
//   },
//   {
//     icon: YouTubeIcon,
//     link: "https://www.youtube.com/",
//     tooltip: "YouTube",
//   },
// ];

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="center">
          <div className="copyright">
            {l("footer_copyright")}{" "}
            <a
              href="https://botmaker.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              {l("footer_privacy_policy")}
            </a>
          </div>
        </div>
        {/*<div className="social">*/}
        {/*  {SOCIAL.map((social) => (*/}
        {/*    <IconButton*/}
        {/*      key={social.tooltip}*/}
        {/*      className="social-icon"*/}
        {/*      onClick={() => window.open(social.link, "_blank")}*/}
        {/*    >*/}
        {/*      <social.icon />*/}
        {/*    </IconButton>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    </footer>
  );
};

export default Footer;
