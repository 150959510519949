import { useState, useMemo } from "react";
import {
  STATUS_COLOR_BAR_BORDER,
  formatDateHHMM,
  formatDateFromToHHMM,
  formatDateHH,
  formatMSasSeconds,
  formatDateDDMMYYYY,
  formatInteger,
} from "../../Utils";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ReferenceLine,
} from "recharts";
import { l } from "../../Lang";
import { Typography, Divider } from "@mui/material";
import Chart from "../main/Chart";
import BackButton from "../common/BackButton";
import "./UptimeBarChart.scss";
import { scalePow } from "d3-scale";

type UptimeBarChartType = {
  metric: any;
};

const tooltipText = {
  "agent-access-time": "segundos",
  "agent-get-message-time": "segundos",
  "online-agents": "agentes",
  "status-queue-waiting": "usuarios",
  "status-queue-waiting-secs": "segundos",
  "status_wapp_mon-api-send-message": "mensajes",
  "status_wapp_mon-operator-msg": "mensajes",
  "status_wapp_mon-user": "mensajes",
  "web-console-access": "agentes",
  "web-console-get-customers": "usuarios",
  "web-console-get-messages": "mensajes",
};

const metricThresholds = {
  "agent-access-time": {
    warning: 10001,
    error: 20001,
  },
  "agent-get-message-time": {
    warning: 10001,
    error: 20001,
  },
  // "status-queue-waiting-secs": {
  //   warning: 200000,
  //   error: 250000,
  // },
};

const formatTooltip = (metricName: string, value: number) => {
  if (value === 0) return l("tooltip_no_data");
  const text = tooltipText[metricName as keyof typeof tooltipText] ?? "";
  const formattedValue =
    text === "segundos" ? formatMSasSeconds(value) : formatInteger(value);

  return `${formattedValue} ${l(`tooltip_${text}`)}`;
};

const tooltipHeader = (metricName: string) => {
  const text = `tooltip_${tooltipText[metricName as keyof typeof tooltipText]}`;
  return `${l("tooltip_avg")} ${l(text)?.toLowerCase()}`;
};

const formatAxis = (metricName: string, value: number) => {
  const text = tooltipText[metricName as keyof typeof tooltipText] ?? "";
  const formattedValue =
    text === "segundos" ? formatMSasSeconds(value) : formatInteger(value);

  return text === "segundos" ? `${formattedValue}s` : formattedValue;
};

// const AMOUNT_TO_SHOW = 100;
// const AMOUNT_TO_SHOW_MOBILE = 8;

const UptimeBarChart = ({ metric }: UptimeBarChartType) => {
  const { metricName, points, desc, label } = metric;
  const [hoveredBar, setHoveredBar] = useState<number | null>(null);
  const [selectedHour, setSelectedHour] = useState<string | null>(null);
  const points24hs = useMemo(
    () => points.filter((point: any) => point.is24hs),
    [points]
  );
  const [points5minutes, setPoints5minutes] = useState<any>(null);

  if (!points.length) return;

  const theresholds =
    metricThresholds[metricName as keyof typeof metricThresholds];

  const pointsToShow = !selectedHour ? points24hs : points5minutes;

  // TO TEST!!!
  // Rondomly generate the amount of 'ok'
  // pointsToShow.forEach((point: any) => {
  //   point.value = Math.random() < 0.5 ? 0 : point.value;
  // });

  // TO TEST if 1 point is REALLY big
  // if (selectedHour) pointsToShow[7].value = 50000000;

  const handleDetailsClick = (iso: string) => {
    if (selectedHour) return;
    setSelectedHour(iso);
    setPoints5minutes(
      points.filter(
        (point: any) =>
          !point.is24hs && point.iso.substring(0, 13) === iso.substring(0, 13)
      )
    );
  };

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const howExtraInfo = payload[0].payload.is24hs && payload[0].value !== 0;
      return (
        <div className="custom-tooltip">
          <div className="custom-tooltip-header">
            <div>
              {howExtraInfo && (
                <div className="custom-tooltip-info">
                  {tooltipHeader(metricName)}
                </div>
              )}
              <div className="custom-tooltip-text">
                {formatTooltip(metricName, payload[0].value)}
              </div>
            </div>
            <div className="custom-tooltip-date">
              {payload[0].payload.is24hs
                ? formatDateFromToHHMM(payload[0].payload.iso)
                : formatDateHHMM(payload[0].payload.iso)}
              <span> | </span>
              {formatDateDDMMYYYY(payload[0].payload.iso)}
            </div>
          </div>
          {howExtraInfo && (
            <div className="custom-tooltip-details-btn">
              <Typography>{l("click_more_details")}</Typography>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const yAxisFormatter = (value: number) => {
    return formatAxis(metricName, value);
  };

  const xAxisFormatter = (value: string) => {
    return selectedHour ? formatDateHHMM(value) : formatDateHH(value);
  };

  const getBarColor = (value: number, index: number) => {
    const isHovering = hoveredBar === index;

    const colorToShow = !theresholds
      ? STATUS_COLOR_BAR_BORDER[0]
      : value >= theresholds.error
      ? STATUS_COLOR_BAR_BORDER[2]
      : value >= theresholds.warning
      ? STATUS_COLOR_BAR_BORDER[1]
      : STATUS_COLOR_BAR_BORDER[0];

    // Adds .8 alpha
    return isHovering && !selectedHour ? `${colorToShow}CC` : colorToShow;
  };

  const avgValue =
    pointsToShow.reduce((acc: number, point: any) => {
      return acc + point.value;
    }, 0) / pointsToShow.length;

  const maxValue = pointsToShow.reduce((acc: number, point: any) => {
    return Math.max(acc, point.value);
  }, 0);

  const minValue = pointsToShow.reduce((acc: number, point: any) => {
    return Math.min(acc, point.value);
    // return point.value !== 0 ? Math.min(acc, point.value) : acc;
  }, 999999999999);

  const usePow = avgValue * 5 < maxValue;

  return (
    <Chart title={label} subTitle={desc}>
      <span
        className={`uptime-bar-chart-container ${
          selectedHour ? "big" : "small"
        }`}
      >
        <div className={`custom-x-axis-line ${selectedHour ? "show" : ""}`}>
          <BackButton
            onBack={() => setSelectedHour(null)}
            title={l("back_to_24hs")}
          />
          <Typography>{l(`graph_label_${metricName}`)}</Typography>
        </div>
        <ResponsiveContainer
          className={`container ${
            selectedHour ? "x-axis-show" : "x-axis-hidden"
          }`}
          height={275}
        >
          <BarChart
            data={pointsToShow}
            margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
          >
            <CartesianGrid vertical={false} stroke="var(--c-gray-100)" />
            {theresholds && (
              <>
                <ReferenceLine
                  y={theresholds.warning}
                  stroke={STATUS_COLOR_BAR_BORDER[1]}
                  strokeDasharray="3 3"
                />
                <ReferenceLine
                  y={theresholds.error}
                  stroke={STATUS_COLOR_BAR_BORDER[2]}
                  strokeDasharray="3 3"
                />
              </>
            )}
            <XAxis
              dataKey="iso"
              tickFormatter={xAxisFormatter}
              axisLine={false}
              tickLine={false}
              tickMargin={12}
            />
            <YAxis
              tickFormatter={yAxisFormatter}
              axisLine={false}
              tickLine={false}
              tickMargin={12}
              scale={scalePow().exponent(usePow ? 0.1 : 1)}
              domain={
                usePow ? [minValue * 0.9, maxValue * 1.1] : [0, maxValue * 1.1]
              }
            />
            <Tooltip content={<CustomTooltip />} cursor={false} active={true} />
            <Bar
              type="monotone"
              dataKey="value"
              onMouseEnter={(data: any, index: number) => setHoveredBar(index)}
              onMouseLeave={() => setHoveredBar(null)}
              onMouseUp={(data: any) => handleDetailsClick(data.iso)}
            >
              {pointsToShow.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  className={!selectedHour ? "hover-cursor" : ""}
                  fill={getBarColor(entry.value, index)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </span>
    </Chart>
  );
};

export default UptimeBarChart;
