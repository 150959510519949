import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const MonthlyUptime = (props) => {
  const { data } = props;

  // console.log('data', data);

  const renderTable = (arr) => (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>First measurement date</TableCell>
            <TableCell>Last measurement date</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(arr || []).map((row) => (
            <TableRow
              key={row.label}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell>{row.first}</TableCell>
              <TableCell>{row.last}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div style={{ padding: "1.8em" }}>
      <h1>Internal uptime (only botmakers)</h1>
      <h3>Current month</h3>
      {renderTable(data.current)}
      <br />
      <br />
      <h3>Previous month</h3>
      {renderTable(data.previous)}
    </div>
  );
};
export default MonthlyUptime;
