import ReactFlagsSelect from "react-flags-select";
import { l } from "../../Lang";
import "./LangSelector.scss";

const TRANSLATE = {
  "es-ES": "ES",
  "pt-BR": "BR",
  "en-US": "US",
  ES: "es-ES",
  BR: "pt-BR",
  US: "en-US",
};

type LangSelectorProps = {
  lang: string;
  change: (lang: string) => void;
};

const LangSelector = ({ lang, change }: LangSelectorProps) => (
  <div className="lang-selector">
    <ReactFlagsSelect
      className="lang-flags"
      countries={["US", "BR", "ES"]}
      customLabels={{
        US: l("languages_en"),
        BR: l("languages_pt"),
        ES: l("languages_es"),
      }}
      selected={TRANSLATE[lang as keyof typeof TRANSLATE]}
      showSelectedLabel={false}
      onSelect={(country) => {
        change(TRANSLATE[country as keyof typeof TRANSLATE]);
      }}
    />
  </div>
);

export default LangSelector;
